import * as React from "react"
import { navigate } from "gatsby"
import Icon from '../images/svg/close.svg'

// styles
const pageStyles = {
  color: "#232129",
  padding: "2.5% 0",
  wordBreak: 'break-word',
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  textAlign: 'center'
}

// markup
const Impressum = () => {
  return (
    <main style={pageStyles}>
      <Icon className="modal-close" onClick={()=>navigate('/')}/>
      <h1 style={headingStyles}>Impressum</h1>
        <b>Weingut Krispel GmbH</b><br />
        Geschäftsführer: Stefan Krispel<br />
        Neusetz 29,<br />
        A-8345 Hof bei Straden<br />
        Tel: +43/3473/7862<br />
        Fax: +43/3473/7862 4<br />
        Homepage:  www.klasse-steira.at<br />
        E-Mail: office@krispel.at<br />
        Gerichtsstand: Graz<br />
        LG für ZRS Graz<br />
        FN 400529p<br />
        <br />
        UID Nummer: ATU68642757<br />
        <br />
        Bankverbindung: Steiermärkische Sparkasse<br />
        BLZ: 20815<br />
        Kto: 07700003655<br />
        IBAN: AT40 2081 5077 0000 3655<br />
        BIC/SWIFT-Code: STSPAT2G<br />
        <br />
        <b>GLN</b> (der öffentlichen Verwaltung): 9110018100478<br />
        <br />
        <b>Behörde gem. ECG (E-Commerce Gesetz):</b><br />Bezirkshauptmannschaft Südoststeiermark<br />
        <br />
        <b>Mitglied der Wirtschaftskammer:</b><br />
        Fachgruppe Hotellerie (<a href="https://www.wko.at/service/dienststelle.html?orgid=16183" target="_blank" rel="noreferrer">https://www.wko.at/service/dienststelle.html?orgid=16183</a>), Gasthöfe mit Beherbergung ab 9 Gästebetten<br />
        Landesgruppe Agrarhandel (<a href="https://www.wko.at/service/dienststelle.html?orgid=15896" target="_blank" rel="noreferrer">https://www.wko.at/service/dienststelle.html?orgid=15896</a>), Handel mit sonstigen landwirtschaftlichen Produkten<br />
        Landesgruppe Weinhandel (<a href="https://www.wko.at/service/dienststelle.html?orgid=16060" target="_blank" rel="noreferrer">https://www.wko.at/service/dienststelle.html?orgid=16060</a>), Handel mit Wein und Weinmost, Spirituosen, Obstwein und -most<br />
        Landesinnung Lebensmittelgewerbe (<a href="https://www.wko.at/service/dienststelle.html?orgid=44253" target="_blank" rel="noreferrer">https://www.wko.at/service/dienststelle.html?orgid=44253</a>), Erzeuger von Sekt und Spirituosen einschließlich Essig, Essenzen<br />
        <br />
        <b>Berechtigungen aufrecht:</b><br />
        Erzeugung von Lebensmitteln mit Ausnahme der reglementierten Nahrungsmittelerzeugung.<br />
        Seit 24.03.2016 für den Standort 8345 Straden, Neusetz 29 (kann vom Gründungsdatum abweichen).<br />
        GeschäftsführerIn gewerberechtlich: Stefan Krispel<br />
        Gastgewerbe, in der Betriebsart Gasthof gemäß § 111 Abs. 1 Ziffer 1 und 2 GewO 1994.<br />
        Seit 16.06.2015 für den Standort 8345 Straden, Neusetz 29 (kann vom Gründungsdatum abweichen).<br />
        GeschäftsführerIn gewerberechtlich: Lisa Krispel<br />
        Handelsgewerbe und Handelsagenten, eingeschränkt auf den Weinhandel und den Handel mit landwirtschaftlichen Produkten.<br />
        Seit 16.06.2015 für den Standort 8345 Straden, Neusetz 29 (kann vom Gründungsdatum abweichen).<br />
        GeschäftsführerIn gewerberechtlich: Stefan Krispel<br />
        <br />
        <b>Konzept, Design:</b> Madison Werbeagentur<br />
        <b>Entwicklung:</b> TRONIC Innovation<br />
        <b>Bilder:</b> Weingut Krispel GmbH, Chris Rogl<br />
        <br />
        <b>Rechtliche Hinweise: </b><br />
        Unsere Webseite ist urheberrechtlich geschützt. Eine Verwertung zu kommerziellen oder politischen Zwecken, im Ganzen, auszugsweise oder auch in veränderter Form, bedarf grundsätzlich der vorherigen schriftlichen Zustimmung der Weingut Krispel GmbH. Zulässig ist der Ausdruck, das Kopieren oder das Herunterladen von Dateien zur ausschließlich privaten Nutzung.<br />
        Auf unseren Webseiten befindliche Links zu Webseiten Dritter unterliegen in keiner Weise unserem Einfluss. Krispel ist, sofern nicht anders angegeben, für deren Inhalt nicht verantwortlich.<br />
        Trotz sorgfältiger Zusammenstellung der Webseiten kann für Richtigkeit und Vollständigkeit der gemachten Angaben keine Haftung übernommen werden. Änderungen jederzeit vorbehalten.<br />
        <br />
        © 2021 Weingut Krispel GmbH. Alle Rechte vorbehalten.
   </main>
  )
};

export default Impressum